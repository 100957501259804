import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageAnalytics = Loadable(lazy(() => import('../pages/PageAnalytics')));
export const PageIssues = Loadable(lazy(() => import('../pages/PageIssues')));
export const PageRules = Loadable(lazy(() => import('../pages/PageRules')));
export const PageJobs = Loadable(lazy(() => import('../pages/PageJobs')));
export const PageReports = Loadable(lazy(() => import('../pages/PageReports')));

export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
