import { useState, useCallback } from 'react';
// msal
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
// axios
import axios from 'axios';
// import axiosAPI from '../utils/axiosAPI';
// config
import { HOST_API_KEY_API, OAUTH2 } from '../config-global';


const axiosInstance = axios.create({ baseURL: HOST_API_KEY_API });
axiosInstance.interceptors.request.use(
    async config => {
        const accessToken = localStorage.getItem('auth2');
        config.headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*'
        }
        return config;
    },
    error => {
        Promise.reject(error)
    }
);
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);


const useAxiosAPI = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

        const refreshToken = useCallback(async () => {
            // t();
            let token = "anon";
            if (accounts && isAuthenticated) {
                const response = await instance.acquireTokenSilent({
                    scopes: [OAUTH2.scope],
                    account: accounts[0],
                });
                if (response) {
                    token = response.accessToken;
                }
            }
            else {
                token = "anon";
            }
            localStorage.setItem("auth2", token);
            console.log(`endrefreshToken ${token}`);
        }, [accounts, instance, isAuthenticated]);
        const getData = useCallback(async (url) => {
            try {
                setIsLoading(true);
//                console.log(`useAxiosAPI(${url}): start`)
                let response = null;
                try {
                    response = await axiosInstance.get(url);
                } catch (error) {
                    console.log(error);
                    if (error.response.status === 401) {
                        await refreshToken();
                        response = await axiosInstance.get(url);
                    }
                }
                setIsLoading(false);
                console.log(`useAxiosAPI(${url}): ${response?.data?.length ?? JSON.stringify(response?.data ?? {})}`)
                return response.data;
            } catch (error) {
                setIsLoading(false);
                setIsError(true);
                console.error(error);
                return null;
            }
        },[refreshToken]);


    return { isLoading, isError, getData };
};
export default useAxiosAPI;


/*
import React from 'react';
import useAxios from '../hooks/useAxios';
import Notes from './Notes';
const AxiosDemo = () => {
  const { isLoading, isError, data: notes } = useAxios(
    'http://jsonplaceholder.typicode.com/posts'
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <h1>Using Axios</h1>
      {notes && <Notes data={notes} />}
      {isError && <div>Error fetching data.</div>}
    </div>
  );
};
export default AxiosDemo;
*/