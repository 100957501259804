
import PropTypes from 'prop-types';
import React, { useContext, useState, useCallback, useEffect, useMemo, createContext } from "react";
// import axios from "axios";
import { v4 as uuid } from 'uuid';
// hooks
import useAxiosAPI from '../hooks/useAxiosAPI';
import { _statusData, _actionsData, _priorityData, _deadlineRangeData } from '../_mock/_statusData';


const APIContext = createContext();

APIContextProvider.propTypes = {
    children: PropTypes.node,
};

export function APIContextProvider({ children }) {

    const dashboardKpi_default = [
        {
            title: "Weekly Sales",
            value: 714000,
            color: "success",
            icon: "ant-design:android-filled",
        },
        {
            title: "New user",
            value: 1352831,
            color: "info",
            icon: "ant-design:apple-filled",
        },
        {
            title: "Item orders",
            value: 1723315,
            color: "warning",
            icon: "ant-design:windows-filled",
        },
        {
            title: "Bug reports",
            value: 234,
            color: "error",
            icon: "ant-design:bug-filled",
        }
    ];
    const dashboardPie_default = [
        { title: 'Rule 1', value: 4344, color: 'primary' },
        { title: 'Rule 2', value: 5435, color: 'info' },
        { title: 'Rule 3', value: 1443, color: 'error' },
        { title: 'Rule 4', value: 4443, color: 'warning' },
    ];
    const dashboardGraph_default =
        [
            {
                name: 'Team A',
                type: 'column',
                fill: 'solid',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 39],
            },
            {
                name: 'Team B',
                type: 'area',
                fill: 'gradient',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43, 41],
            },
            {
                name: 'Team C',
                type: 'line',
                fill: 'solid',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39, 42],
            },
        ];
    const users_default = [
        {
            "id": 0,
            "user_id": 0,
            "code": "System",
            "name": "System",
            "email": "System_default",
            "firstname": "System",
            "lastname": "System_default",
            "suffix": "System",
            "info": null,
            "birthdate": "1900-01-01T00:00:00",
            "create_datime": "2022-12-14T16:13:09.14",
            "change_datetime": null
        },
        {
            "id": 1,
            "user_id": 1,
            "code": "Scheduled",
            "name": "Scheduled",
            "email": "Scheduled_default",
            "firstname": "Scheduled",
            "lastname": "Scheduled_default",
            "suffix": "Scheduled",
            "info": null,
            "birthdate": "1900-01-01T00:00:00",
            "create_datime": "2022-12-18T00:51:45.45",
            "change_datetime": null
        },
        {
            "id": 2,
            "user_id": 2,
            "code": "frank@dataqualitycompany.app",
            "name": "Frank",
            "email": "frank@sifratour.net",
            "firstname": "Frank",
            "lastname": "Blom",
            "suffix": "FB",
            "info": " ",
            "birthdate": "1969-01-13T00:00:00",
            "create_datime": "2022-12-14T16:12:45.747",
            "change_datetime": null
        },
        {
            "id": 3,
            "user_id": 3,
            "code": "mihai@dataqualitycompany.app",
            "name": "Mihai",
            "email": "Mihaidanescu@gmail.com",
            "firstname": "Mihai",
            "lastname": "Danescu",
            "suffix": "MD",
            "info": "green",
            "birthdate": "1971-02-11T00:00:00",
            "create_datime": "2022-12-14T16:13:09.14",
            "change_datetime": null
        },
        {
            "id": 4,
            "user_id": 4,
            "code": "karin@dataqualitycompany.app",
            "name": "Karin",
            "email": null,
            "firstname": "Karin",
            "lastname": "van der Vliet",
            "suffix": "KV",
            "info": "blue",
            "birthdate": null,
            "create_datime": "2022-12-18T10:27:38.817",
            "change_datetime": null
        },
        {
            "id": 5,
            "user_id": 5,
            "code": "rob@dataqualitycompany.app",
            "name": "Rob",
            "email": null,
            "firstname": "Rob",
            "lastname": "van Dijk",
            "suffix": "RD",
            "info": "red",
            "birthdate": null,
            "create_datime": "2022-12-18T10:27:45.603",
            "change_datetime": null
        }
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState(users_default);
    const [user, setUser] = useState([]);
    const [apiToken, setApiToken] = useState("anon");

    const [notifications, setNotifications] = useState([]);

    const [dashboardKpi, setDashboardKpi] = useState(dashboardKpi_default);
    const [dashboardPie, setDashboardPie] = useState(dashboardPie_default);
    const [dashboardGraph, setDashboardGraph] = useState(dashboardGraph_default);
    const [analyticsOffset, setAnalyticsOffset] = useState(0);

    const [interval, setInterval] = useState(0);
    const [fullLoad, setFullLoad] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [issuesData, setIssuesData] = useState([]);
    const [rulesData, setRulesData] = useState([{ id: 'all', name: '(alle)', count: 0 }]);
    const [statusData, setStatusData] = useState([{ id: 'all', name: '(alle)', count: 0 }]);
    const [actionsData, setActionsData] = useState([]);
    const [priorityData, setPriorityData] = useState([]);
    const [durationsData, setDurationsData] = useState([]);

    const { getData } = useAxiosAPI();
    /*
        console.log("defaults");
        console.log("dashboardKpi");
        console.log(dashboardKpi_default);
        console.log("dashboardPie");
        console.log(dashboardPie_default);
        console.log("dashboardGraph");
        console.log(dashboardGraph_default);
    */



    const onAddNotification = useCallback(({ title, description, icon }) => {
        const objNotification = { id: uuid(), title, description, icon, createdAt: new Date(), isUnRead: true }
        console.log(`newNotification: ${JSON.stringify(objNotification)}`)
        notifications.unshift(objNotification);
        setNotifications(notifications.slice(0, 10));
    }, [setNotifications, notifications]);

    const onNewNotification = useCallback(({ title, description, icon }) => {
        const newNotifications = [{ id: uuid(), title, description, icon, createdAt: new Date(), isUnRead: true }];
        setNotifications(newNotifications);
    }, [setNotifications]);

    const fetchUsersData = useCallback(async () => {
        console.log(`fetchUsersData apiToken: ${apiToken}`);
        if (apiToken !== "anon") {
            setIsLoading(true);
            const users1 = await getData(`/api/GetUser`);
            if (users1 === null) {
                onNewNotification({ title: 'Error', description: 'API unreacheable', icon: 'material-symbols:error' })
            } else {
                setUsers(users1);
            }
            setIsLoading(false);
        }

    }, [getData, apiToken, onNewNotification])

    const fetchApplicationData = useCallback(async () => {
        console.log(`fetchApplicationData fullLoad: ${fullLoad} apiToken: ${apiToken}`);
        if(apiToken === "anon")
        setFullLoad(false);
        // console.log("users.length",users.length);
        //        console.log('process.env', process.env);
        //
        // https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=VOO&interval=5min&apikey=RNM7AH0WB3LAJLUX
        if (apiToken !== "anon") {
            setIsLoading(true);
            // const usersData1 = await getData(`/api/GetUser`);
            const usersData1 = users;

            if (usersData1 !== null) {
                const issuesData1 = await getData(fullLoad?'/api/getissue':'api/getissue?pagesize=50');
                const issuesData2 = issuesData1.map(({ id, duration, ...rest }) => ({ id, duration, ...rest, duration_id: _deadlineRangeData.filter(({ value1, value2 }) => (duration > value1 || value1 === null) && (duration <= value2 || value2 === null))[0].id }));
                //                console.log("issuesData2",issuesData2);
                setIssuesData(issuesData2);

                const usersData2 = usersData1.map(({ user_id, count, ...rest }) => ({ id: user_id, user_id, ...rest, count: issuesData1.filter((item) => item.handler_id === user_id).length }));
                setUsersData(usersData2);
                // console.log("APIContext users");
                //            console.log(users1);
                const _deadlineRangeData1 = _deadlineRangeData.map(({ id, count, ...rest }) => ({ id, ...rest, count: issuesData2.filter(({ duration_id }) => duration_id === id).length }));
                // console.log("_deadlineRangeData1",_deadlineRangeData1);
                setDurationsData(_deadlineRangeData1)

                const addRuleCount = (rules) => rules.map(({ rule_id, count, ...rest }) => ({ id: rule_id, rule_id, ...rest, count: issuesData1.filter((item) => item.rule_id === rule_id).length }));
                const addStatusCount = (statuses) => statuses.map(({ status_id, count, ...rest }) => ({ id: status_id, status_id, ...rest, count: issuesData1.filter((item) => item.status_id === status_id).length }));
                const all = { id: 'all', name: '(alle)', count: issuesData1.length };

                const _rulesData1 = await getData('/api/getrule')
                const _rulesData = addRuleCount(_rulesData1)
                // _rulesData.unshift(all);
                setRulesData(_rulesData);
                // console.log(_rulesData);
                const _statusData1 = addStatusCount(_statusData);
                // _statusData1.unshift(all);
                setStatusData(_statusData1);
                setActionsData(_actionsData);
                setPriorityData(_priorityData);
            }
            setIsLoading(false);
        }

    }, [getData, apiToken, users, fullLoad,setFullLoad])

    const fetchAnalyticsData = useCallback(async () => {
        console.log(`fetchAnalyticsData apiToken: ${apiToken}`);
        // console.log('process.env', process.env);
        if (apiToken !== "anon") {
            setIsLoading(true);

            const dashboardPie1 = await getData(`/api/GetAnalytics?type=dashboard_pie&offset=${analyticsOffset}`);
//            console.log("APIContext dashboardPie");
            //                console.log(dashboardPie1);
            if (dashboardPie1) {
                setDashboardPie(dashboardPie1);
                //                    onNewNotification({ title: 'Pie data', description: `${dashboardPie1.length} records`, icon: 'mdi:success-circle' })
            }

//            const dashboardKpi1 = await getData(`/api/GetAnalytics?type=dashboard_kpi&offset=-1`);
            const dashboardKpi1 = await getData(`/api/GetAnalytics?type=dashboard_kpi&offset=${analyticsOffset}`);
//            console.log("APIContext dashboardKpi");
            //                console.log(dashboardKpi1);
            if (dashboardKpi1) {
                setDashboardKpi(dashboardKpi1);
                //                    onNewNotification({ title: 'KPI data', description: `${dashboardKpi1.length} records`, icon: 'mdi:success-circle' })
            }

            const dashboardGraph1 = await getData(`/api/GetAnalytics?type=dashboard_graph&offset=${analyticsOffset}`);
            //        const dashboardGraph1 = await getData(`/api/GetAnalytics?type=dashboard_graph&offset=0`);
//            console.log("APIContext dashboardGraph");
            //                console.log(dashboardGraph1);
            const dashboardGraph2 = dashboardGraph1 && dashboardGraph1.map(({ title, color, icon, type, fill, day1, day2, day3, day4, day5, day6, day7, day8, day9, day10, day11, day12, day13, day14,
                day15, day16, day17, day18, day19, day20, day21, day22, day23, day24, day25, day26, day27, day28, day29, day30, day31 }) => (
                {
                    name: title,
                    color,
                    type,
                    fill,
                    data: [day1, day2, day3, day4, day5, day6, day7, day8, day9, day10, day11, day12, day13, day14, day15, day16, day17, day18, day19, day20, day21, day22, day23, day24, day25, day26, day27, day28, day29, day30, day31],
                }))
                ;
            //                console.log(dashboardGraph2);

            if (dashboardGraph2) {
                setDashboardGraph(dashboardGraph2);
                //                    onNewNotification({ title: 'Graph data', description: `${dashboardGraph2.length} records`, icon: 'mdi:success-circle', color: 'blue' })
            }


            setIsLoading(false);
        }

    }, [getData, analyticsOffset, apiToken])

    useEffect(() => {
        const intervalId = interval? setInterval(() => fetchAnalyticsData(), interval) : null;
      
        return () => clearInterval(intervalId); // This is important
       
      }, [fetchAnalyticsData, interval])

    /*
    useEffect(() => {
        const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
          setState(state => ({ data: state.data, error: false, loading: true }))
          fetch(url)
            .then(data => data.json())
            .then(obj =>
              Object.keys(obj).map(key => {
                let newData = obj[key]
                newData.key = key
                return newData
              })
           )
           .then(newData => setState({ data: newData, error: false, loading: false }))
           .catch(function(error) {
              console.log(error)
              setState({ data: null, error: true, loading: false })
           })
        }, 5000)
      
        return () => clearInterval(intervalId); //This is important
       
      }, [url, useState])
      */    

//    useEffect(() => {
//        fetchUsersData();
//    }, [fetchUsersData]);

    useEffect(() => {
        fetchAnalyticsData();
    }, [fetchAnalyticsData]);

    useEffect(() => {
        fetchApplicationData();
    }, [fetchApplicationData]);
/*
    useEffect(() => {
        let ignore = false;
      
        async function startFetching() {
          const json = await fetchTodos(userId);
          if (!ignore) {
            setTodos(json);
          }
        }
      
        startFetching();
      
        return () => {
          ignore = true;
        };
      }, [userId]);
*/
    const onApplicationRefresh = useCallback(() => fetchApplicationData(), [fetchApplicationData]);
    const onAnalyticsRefresh = useCallback(() => fetchAnalyticsData(), [fetchAnalyticsData]);
    /*
        useEffect(() => {
            async function fetchData() {
                //            const { data } = await axios.get(
                //                `https://jsonplaceholder.typicode.com/users`
                //            );
                const data = users_default;
                console.log(data);
                setUsers(data);
            }
            fetchData();
        }, []);
    */
    const onChangeUser = useCallback((newUserEmail) => {
        console.log(`onChangeUser: ${newUserEmail}`)
        console.log('process.env', process.env);
        console.log("user",user);
        if (!(user && user.code === newUserEmail))
            setUser(users && users.find(u => u.code === newUserEmail));
    }, [setUser, users, user ]);

    const onChangeApiToken = useCallback((newApiToken) => {
        setApiToken(newApiToken);
    }, [setApiToken]);




    const findUser = useCallback(
        user_id => usersData.find(({ id }) => id === user_id
        ), [usersData]);
    const findRule = useCallback(
        find_id => rulesData.find(({ id }) => id === find_id
        ), [rulesData]);
    const findStatus = useCallback(
        find_id => statusData.find(({ id }) => id === find_id
        ), [statusData]);


    const memoizedValue = useMemo(
        () => ({
            getData,

            users,
            setUsers,

            user,
            onChangeUser,

            apiToken,
            onChangeApiToken,
            //            onExpireToken,
            notifications,
            onAddNotification,
            setNotifications,

            dashboardKpi,
            dashboardPie,
            dashboardGraph,
            setAnalyticsOffset,
            onAnalyticsRefresh,

            usersData,
            issuesData,
            setFullLoad,
            setIssuesData,
            rulesData,
            statusData,
            actionsData,
            priorityData,
            durationsData,
            onApplicationRefresh,

            findUser,
            findRule,
            findStatus,
            isLoading,
            setInterval,
        }),
        [
            getData,

            users,
            setUsers,

            user,
            onChangeUser,

            apiToken,
            onChangeApiToken,
            //            onExpireToken,
            notifications,
            onAddNotification,
            setNotifications,

            dashboardKpi,
            dashboardPie,
            dashboardGraph,
            setAnalyticsOffset,
            onAnalyticsRefresh,

            usersData,
            issuesData,
            setFullLoad,
            setIssuesData,
            rulesData,
            statusData,
            actionsData,
            priorityData,
            durationsData,
            onApplicationRefresh,

            findUser,
            findRule,
            findStatus,
            isLoading,
            setInterval,
        ]
    );

    return (<APIContext.Provider value={memoizedValue}>{children}</APIContext.Provider>);


}

export function useAPI() {
    const context = useContext(APIContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
}
