import React from 'react';
import ReactDOM from 'react-dom/client';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './auth/authConfig';
// ----------------------------------------------------------------------

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(<App />);

// root.render(
// <AzureAD provider={authProvider} forceLogin>
// <App />
// </AzureAD>
// );

// root.render(
//     <React.StrictMode>
//         <MsalProvider instance={msalInstance}>
//             <App />
//         </MsalProvider>
//     </React.StrictMode>
// );

root.render(
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
