// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  // mine
  Dense: 'Dense',

  // menu
  Home: `Home`,
  Dashboard: 'Dashboard',
  Issues: `Issues`,
  Rules: `Rules`,
  Jobs: `Jobs`,
  // Management: `User`,
  // Profile: `Profile`,
  // Settings: `Settings`,

  // account
  Login: `Login`,
  Logout: `Logout`,

  // analytics
  Refresh: `Refresh`,
  Last_Month: `Last Month`,
  This_Month: `This Month`,
  Open_issues: `Open issues`,
  Handled_issues_this_month: `Handled issues this month`,
  Created_issues_this_month: `Created issues this month`,
  Handled: `Handled`,
  // Created: `Created`,
  Open: `Open`,

  Issues_per_day: `Issues per day`,
  Issues_per_rule: `Issues per rule`,

  // filter
  Filters: `Filters`,
  issues_found: `issues found.`,
  Clear: `Clear`,
  high: 'high',
  medium: 'medium',
  low: 'low',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  from: 'from',
  to: 'to',

    

  // issue
  Rule: `Rule`,
  Created: `Created`,
  Status: `Status`,
  Handler: `Handler`,
  Priority: `Priority`,
  Deadline: `Deadline`,
  Duration: `Duration`,
  Actions: `Actions`,
  //  Job: `Taak`,

  // issue row
  Description: 'Description',
  Details: `Details`,
  History: `History`,

  DQC_ID: `DQC ID`,
  DQC_Value: `DQC Value`,
  DQC_Message: `DQC Message`,
  Comment: `Comment`,

  Change_Date: `Change Date`,
  From_Status: `From Status`,
  To_Status: `To Status`,
  Author: `Author`,
  // status
  Assess: 'Assess',
  Approve: 'Approve',
  Confirmed: 'Confirmed',
  Corrected: 'Corrected',
  Confirm_rejection: 'Confirm Rejection',
  Rejected: 'Rejected',
  Confirm_exception: 'Confirm Exception',
  Excepted: 'Excepted',
  Selfcorrected: 'Self Corrected',
  // actions
  Change_handler: `Change handler`,
  Propose_remediation: 'Propose remediation',
  Propose_exception: 'Propose exception',
  Propose_rejection: 'Propose rejection',
  Approve_remediation: 'Approve remediation',
  Reject_remediation: 'Reject remediation',
  Undo_remediation: 'Undo remediation',
  Approve_rejection: 'Approve rejection',
  Reject_rejection: 'Reject rejection',
  Undo_rejection: 'Undo rejection',
  Approve_exception: 'Approve exception',
  Reject_exception: 'Reject exception',
  Undo_exception: 'Undo exception',

  Execute_rule: 'Execute rule',
  Notifications: 'Notifications',
  You_have: 'You have',
  unread_messages: 'unread messages',
  Mark_all_as_read: 'Mark all as read',
  New_messages: 'New messages',
  Before_that: 'Read messages',

};

export default en;
