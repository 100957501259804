// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD1 = '/dashboard';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,

  dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  analytics: path(ROOTS_DASHBOARD, '/dashboard'),
  issues: path(ROOTS_DASHBOARD, '/issues'),
  rules: path(ROOTS_DASHBOARD, '/rules'),
  jobs: path(ROOTS_DASHBOARD, '/jobs'),
  reports: path(ROOTS_DASHBOARD, '/reports'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};
