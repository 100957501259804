// import { alpha, darken, lighten } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const DEFAULT = {
  lighter: GREY[200],
  light: GREY[400],
  main: GREY[600],
  dark: GREY[700],
  darker: GREY[800],
  contrastText: '#fff',
};

const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#00AB55',
  dark: '#007B55',
  darker: '#005249',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#D8FBDE',
  light: '#86E8AB',
  main: '#36B37E',
  dark: '#1B806A',
  darker: '#0A5554',
  contrastText: '#fff',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: GREY[800],
};

// const war = {
//   "lighter": "rgb(255, 229, 178)", 
//   "light": "rgb(255, 204, 102)", 
//   "main": "#FFAB00", 
//   "dark": "rgb(153, 102, 0)", 
//   "darker": "rgb(76, 51, 0)", 
//   "contrastText": "#fff"
// };

// const lemonchiffon = {
//   "lighter": "rgb(255, 253, 240)",
//   "light": "rgb(255, 252, 225)",
//   "main": "#fffacd",
//   "dark": "rgb(153, 150, 123)",
//   "darker": "rgb(76, 75, 61)",
//   "contrastText": "#fff"
// };

const ERROR = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#fff',
};

const COMMON = {
  default: DEFAULT,
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  action: {
    odd: alpha(PRIMARY.light, 0.16),
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

/*
const addColor = (name, hex)=>{
const o={
    lighter:  lighten(hex, 0.7),
    light:  lighten(hex, 0.4),
    main: hex,
    dark: darken(hex, 0.4),
    darker: darken(hex, 0.7),
    contrastText: '#fff',    
};
console.log (` "${name}": ${JSON.stringify(o)},`);
}
addColor("WARN","#FFAB00");
*/

// const colours = {
//   "war": "#FFAB00"
// }
// const aColours = Object.keys(colours).map((k) => (       {         [k]: {
//     lighter:  lighten(colours[k], 0.7),
//     light:  lighten(colours[k], 0.4),
//     main: colours[k],
//     dark: darken(colours[k], 0.4),
//     darker: darken(colours[k], 0.7),
//     contrastText: '#fff',
//   }       })     );

// /* eslint-disable */

// const res = aColours.reduce((acc,curr)=> (acc[Object.keys(curr)[0]]=Object.values(curr)[0],acc),{});
// console.log(JSON.stringify( res));
// /* eslint-enable */


// https://www.w3schools.com/colors/colors_names.asp
const allColors =
{
  "darkyellow": {
      "lighter": "rgb(255, 229, 178)", 
      "light": "rgb(255, 204, 102)", 
      "main": "#FFAB00", 
      "dark": "rgb(153, 102, 0)", 
      "darker": "rgb(76, 51, 0)", 
      "contrastText": "#fff"
  },
  // CYAN
  "preset-cyan":{
    "lighter": "#CCF4FE",
    "light": "#68CDF9",
    "main": "#078DEE",
    "dark": "#0351AB",
    "darker": "#012972",
    "contrastText": "#FFFFFF",
  },
  // PURPLE
  "preset-purple":{
    "lighter": "#EBD6FD",
    "light": "#B985F4",
    "main": "#7635dc",
    "dark": "#431A9E",
    "darker": "#200A69",
    "contrastText": "#FFFFFF",
  },
  // BLUE
  "preset-blue":{
    "lighter": "#D1E9FC",
    "light": "#76B0F1",
    "main": "#2065D1",
    "dark": "#103996",
    "darker": "#061B64",
    "contrastText": "#FFFFFF",
  },
  // ORANGE
  "preset-orange":{
    "lighter": "#FEF4D4",
    "light": "#FED680",
    "main": "#fda92d",
    "dark": "#B66816",
    "darker": "#793908",
    "contrastText": "#212B36",
  },
  // RED
  "preset-red":{
    "lighter": "#FFE3D5",
    "light": "#FFC1AC",
    "main": "#FF3030",
    "dark": "#B71833",
    "darker": "#7A0930",
    "contrastText": "#FFFFFF",
  },  
  "aliceblue": {
    "lighter": "rgb(250, 252, 255)",
    "light": "rgb(246, 250, 255)",
    "main": "#f0f8ff",
    "dark": "rgb(144, 148, 153)",
    "darker": "rgb(72, 74, 76)",
    "contrastText": "#fff"
  },
  "antiquewhite": {
    "lighter": "rgb(253, 249, 243)",
    "light": "rgb(252, 243, 231)",
    "main": "#faebd7",
    "dark": "rgb(150, 141, 129)",
    "darker": "rgb(75, 70, 64)",
    "contrastText": "#fff"
  },
  "aqua": {
    "lighter": "rgb(178, 255, 255)",
    "light": "rgb(102, 255, 255)",
    "main": "#00ffff",
    "dark": "rgb(0, 153, 153)",
    "darker": "rgb(0, 76, 76)",
    "contrastText": "#fff"
  },
  "aquamarine": {
    "lighter": "rgb(216, 255, 242)",
    "light": "rgb(178, 255, 229)",
    "main": "#7fffd4",
    "dark": "rgb(76, 153, 127)",
    "darker": "rgb(38, 76, 63)",
    "contrastText": "#fff"
  },
  "azure": {
    "lighter": "rgb(250, 255, 255)",
    "light": "rgb(246, 255, 255)",
    "main": "#f0ffff",
    "dark": "rgb(144, 153, 153)",
    "darker": "rgb(72, 76, 76)",
    "contrastText": "#fff"
  },
  "beige": {
    "lighter": "rgb(252, 252, 244)",
    "light": "rgb(249, 249, 234)",
    "main": "#f5f5dc",
    "dark": "rgb(147, 147, 132)",
    "darker": "rgb(73, 73, 66)",
    "contrastText": "#fff"
  },
  "bisque": {
    "lighter": "rgb(255, 246, 237)",
    "light": "rgb(255, 238, 219)",
    "main": "#ffe4c4",
    "dark": "rgb(153, 136, 117)",
    "darker": "rgb(76, 68, 58)",
    "contrastText": "#fff"
  },
  "black": {
    "lighter": "rgb(178, 178, 178)",
    "light": "rgb(102, 102, 102)",
    "main": "#000000",
    "dark": "rgb(0, 0, 0)",
    "darker": "rgb(0, 0, 0)",
    "contrastText": "#fff"
  },
  "blanchedalmond": {
    "lighter": "rgb(255, 249, 240)",
    "light": "rgb(255, 243, 225)",
    "main": "#ffebcd",
    "dark": "rgb(153, 141, 123)",
    "darker": "rgb(76, 70, 61)",
    "contrastText": "#fff"
  },
  "blue": {
    "lighter": "rgb(178, 178, 255)",
    "light": "rgb(102, 102, 255)",
    "main": "#0000ff",
    "dark": "rgb(0, 0, 153)",
    "darker": "rgb(0, 0, 76)",
    "contrastText": "#fff"
  },
  "blueviolet": {
    "lighter": "rgb(219, 191, 246)",
    "light": "rgb(184, 127, 237)",
    "main": "#8a2be2",
    "dark": "rgb(82, 25, 135)",
    "darker": "rgb(41, 12, 67)",
    "contrastText": "#fff"
  },
  "brown": {
    "lighter": "rgb(228, 191, 191)",
    "light": "rgb(201, 127, 127)",
    "main": "#a52a2a",
    "dark": "rgb(99, 25, 25)",
    "darker": "rgb(49, 12, 12)",
    "contrastText": "#fff"
  },
  "burlywood": {
    "lighter": "rgb(245, 233, 219)",
    "light": "rgb(235, 212, 183)",
    "main": "#deb887",
    "dark": "rgb(133, 110, 81)",
    "darker": "rgb(66, 55, 40)",
    "contrastText": "#fff"
  },
  "cadetblue": {
    "lighter": "rgb(207, 225, 226)",
    "light": "rgb(159, 196, 198)",
    "main": "#5f9ea0",
    "dark": "rgb(57, 94, 96)",
    "darker": "rgb(28, 47, 48)",
    "contrastText": "#fff"
  },
  "chartreuse": {
    "lighter": "rgb(216, 255, 178)",
    "light": "rgb(178, 255, 102)",
    "main": "#7fff00",
    "dark": "rgb(76, 153, 0)",
    "darker": "rgb(38, 76, 0)",
    "contrastText": "#fff"
  },
  "chocolate": {
    "lighter": "rgb(241, 210, 187)",
    "light": "rgb(228, 165, 120)",
    "main": "#d2691e",
    "dark": "rgb(126, 63, 18)",
    "darker": "rgb(63, 31, 9)",
    "contrastText": "#fff"
  },
  "coral": {
    "lighter": "rgb(255, 216, 202)",
    "light": "rgb(255, 178, 150)",
    "main": "#ff7f50",
    "dark": "rgb(153, 76, 48)",
    "darker": "rgb(76, 38, 24)",
    "contrastText": "#fff"
  },
  "cornflowerblue": {
    "lighter": "rgb(208, 223, 249)",
    "light": "rgb(162, 191, 244)",
    "main": "#6495ed",
    "dark": "rgb(60, 89, 142)",
    "darker": "rgb(30, 44, 71)",
    "contrastText": "#fff"
  },
  "cornsilk": {
    "lighter": "rgb(255, 252, 244)",
    "light": "rgb(255, 250, 234)",
    "main": "#fff8dc",
    "dark": "rgb(153, 148, 132)",
    "darker": "rgb(76, 74, 66)",
    "contrastText": "#fff"
  },
  "crimson": {
    "lighter": "rgb(244, 184, 196)",
    "light": "rgb(234, 114, 138)",
    "main": "#dc143c",
    "dark": "rgb(132, 12, 36)",
    "darker": "rgb(66, 6, 18)",
    "contrastText": "#fff"
  },
  "cyan": {
    "lighter": "rgb(178, 255, 255)",
    "light": "rgb(102, 255, 255)",
    "main": "#00ffff",
    "dark": "rgb(0, 153, 153)",
    "darker": "rgb(0, 76, 76)",
    "contrastText": "#fff"
  },
  "darkblue": {
    "lighter": "rgb(178, 178, 220)",
    "light": "rgb(102, 102, 185)",
    "main": "#00008b",
    "dark": "rgb(0, 0, 83)",
    "darker": "rgb(0, 0, 41)",
    "contrastText": "#fff"
  },
  "darkcyan": {
    "lighter": "rgb(178, 220, 220)",
    "light": "rgb(102, 185, 185)",
    "main": "#008b8b",
    "dark": "rgb(0, 83, 83)",
    "darker": "rgb(0, 41, 41)",
    "contrastText": "#fff"
  },
  "darkgoldenrod": {
    "lighter": "rgb(233, 218, 181)",
    "light": "rgb(212, 182, 108)",
    "main": "#b8860b",
    "dark": "rgb(110, 80, 6)",
    "darker": "rgb(55, 40, 3)",
    "contrastText": "#fff"
  },
  "darkgray": {
    "lighter": "rgb(229, 229, 229)",
    "light": "rgb(203, 203, 203)",
    "main": "#a9a9a9",
    "dark": "rgb(101, 101, 101)",
    "darker": "rgb(50, 50, 50)",
    "contrastText": "#fff"
  },
  "darkgreen": {
    "lighter": "rgb(178, 208, 178)",
    "light": "rgb(102, 162, 102)",
    "main": "#006400",
    "dark": "rgb(0, 60, 0)",
    "darker": "rgb(0, 30, 0)",
    "contrastText": "#fff"
  },
  "darkkhaki": {
    "lighter": "rgb(235, 233, 210)",
    "light": "rgb(215, 211, 166)",
    "main": "#bdb76b",
    "dark": "rgb(113, 109, 64)",
    "darker": "rgb(56, 54, 32)",
    "contrastText": "#fff"
  },
  "darkmagenta": {
    "lighter": "rgb(220, 178, 220)",
    "light": "rgb(185, 102, 185)",
    "main": "#8b008b",
    "dark": "rgb(83, 0, 83)",
    "darker": "rgb(41, 0, 41)",
    "contrastText": "#fff"
  },
  "darkolivegreen": {
    "lighter": "rgb(204, 210, 192)",
    "light": "rgb(153, 166, 130)",
    "main": "#556b2f",
    "dark": "rgb(51, 64, 28)",
    "darker": "rgb(25, 32, 14)",
    "contrastText": "#fff"
  },
  "darkorange": {
    "lighter": "rgb(255, 220, 178)",
    "light": "rgb(255, 186, 102)",
    "main": "#ff8c00",
    "dark": "rgb(153, 84, 0)",
    "darker": "rgb(76, 42, 0)",
    "contrastText": "#fff"
  },
  "darkorchid": {
    "lighter": "rgb(224, 193, 239)",
    "light": "rgb(193, 132, 224)",
    "main": "#9932cc",
    "dark": "rgb(91, 30, 122)",
    "darker": "rgb(45, 15, 61)",
    "contrastText": "#fff"
  },
  "darkred": {
    "lighter": "rgb(220, 178, 178)",
    "light": "rgb(185, 102, 102)",
    "main": "#8b0000",
    "dark": "rgb(83, 0, 0)",
    "darker": "rgb(41, 0, 0)",
    "contrastText": "#fff"
  },
  "darksalmon": {
    "lighter": "rgb(248, 223, 215)",
    "light": "rgb(241, 192, 175)",
    "main": "#e9967a",
    "dark": "rgb(139, 90, 73)",
    "darker": "rgb(69, 45, 36)",
    "contrastText": "#fff"
  },
  "darkseagreen": {
    "lighter": "rgb(221, 234, 221)",
    "light": "rgb(187, 214, 187)",
    "main": "#8fbc8f",
    "dark": "rgb(85, 112, 85)",
    "darker": "rgb(42, 56, 42)",
    "contrastText": "#fff"
  },
  "darkslateblue": {
    "lighter": "rgb(200, 196, 220)",
    "light": "rgb(145, 138, 185)",
    "main": "#483d8b",
    "dark": "rgb(43, 36, 83)",
    "darker": "rgb(21, 18, 41)",
    "contrastText": "#fff"
  },
  "darkslategray": {
    "lighter": "rgb(192, 202, 202)",
    "light": "rgb(130, 149, 149)",
    "main": "#2f4f4f",
    "dark": "rgb(28, 47, 47)",
    "darker": "rgb(14, 23, 23)",
    "contrastText": "#fff"
  },
  "darkturquoise": {
    "lighter": "rgb(178, 240, 241)",
    "light": "rgb(102, 225, 227)",
    "main": "#00ced1",
    "dark": "rgb(0, 123, 125)",
    "darker": "rgb(0, 61, 62)",
    "contrastText": "#fff"
  },
  "darkviolet": {
    "lighter": "rgb(222, 178, 241)",
    "light": "rgb(190, 102, 228)",
    "main": "#9400d3",
    "dark": "rgb(88, 0, 126)",
    "darker": "rgb(44, 0, 63)",
    "contrastText": "#fff"
  },
  "deeppink": {
    "lighter": "rgb(255, 184, 222)",
    "light": "rgb(255, 114, 190)",
    "main": "#ff1493",
    "dark": "rgb(153, 12, 88)",
    "darker": "rgb(76, 6, 44)",
    "contrastText": "#fff"
  },
  "deepskyblue": {
    "lighter": "rgb(178, 235, 255)",
    "light": "rgb(102, 216, 255)",
    "main": "#00bfff",
    "dark": "rgb(0, 114, 153)",
    "darker": "rgb(0, 57, 76)",
    "contrastText": "#fff"
  },
  "dimgray": {
    "lighter": "rgb(210, 210, 210)",
    "light": "rgb(165, 165, 165)",
    "main": "#696969",
    "dark": "rgb(63, 63, 63)",
    "darker": "rgb(31, 31, 31)",
    "contrastText": "#fff"
  },
  "dodgerblue": {
    "lighter": "rgb(187, 221, 255)",
    "light": "rgb(120, 188, 255)",
    "main": "#1e90ff",
    "dark": "rgb(18, 86, 153)",
    "darker": "rgb(9, 43, 76)",
    "contrastText": "#fff"
  },
  "firebrick": {
    "lighter": "rgb(231, 188, 188)",
    "light": "rgb(208, 122, 122)",
    "main": "#b22222",
    "dark": "rgb(106, 20, 20)",
    "darker": "rgb(53, 10, 10)",
    "contrastText": "#fff"
  },
  "floralwhite": {
    "lighter": "rgb(255, 253, 250)",
    "light": "rgb(255, 252, 246)",
    "main": "#fffaf0",
    "dark": "rgb(153, 150, 144)",
    "darker": "rgb(76, 75, 72)",
    "contrastText": "#fff"
  },
  "forestgreen": {
    "lighter": "rgb(188, 220, 188)",
    "light": "rgb(122, 185, 122)",
    "main": "#228b22",
    "dark": "rgb(20, 83, 20)",
    "darker": "rgb(10, 41, 10)",
    "contrastText": "#fff"
  },
  "fuchsia": {
    "lighter": "rgb(255, 178, 255)",
    "light": "rgb(255, 102, 255)",
    "main": "#ff00ff",
    "dark": "rgb(153, 0, 153)",
    "darker": "rgb(76, 0, 76)",
    "contrastText": "#fff"
  },
  "gainsboro": {
    "lighter": "rgb(244, 244, 244)",
    "light": "rgb(234, 234, 234)",
    "main": "#dcdcdc",
    "dark": "rgb(132, 132, 132)",
    "darker": "rgb(66, 66, 66)",
    "contrastText": "#fff"
  },
  "ghostwhite": {
    "lighter": "rgb(252, 252, 255)",
    "light": "rgb(250, 250, 255)",
    "main": "#f8f8ff",
    "dark": "rgb(148, 148, 153)",
    "darker": "rgb(74, 74, 76)",
    "contrastText": "#fff"
  },
  "gold": {
    "lighter": "rgb(255, 243, 178)",
    "light": "rgb(255, 231, 102)",
    "main": "#ffd700",
    "dark": "rgb(153, 129, 0)",
    "darker": "rgb(76, 64, 0)",
    "contrastText": "#fff"
  },
  "goldenrod": {
    "lighter": "rgb(243, 228, 188)",
    "light": "rgb(232, 201, 121)",
    "main": "#daa520",
    "dark": "rgb(130, 99, 19)",
    "darker": "rgb(65, 49, 9)",
    "contrastText": "#fff"
  },
  "gray": {
    "lighter": "rgb(216, 216, 216)",
    "light": "rgb(178, 178, 178)",
    "main": "#808080",
    "dark": "rgb(76, 76, 76)",
    "darker": "rgb(38, 38, 38)",
    "contrastText": "#fff"
  },
  "green": {
    "lighter": "rgb(178, 216, 178)",
    "light": "rgb(102, 178, 102)",
    "main": "#008000",
    "dark": "rgb(0, 76, 0)",
    "darker": "rgb(0, 38, 0)",
    "contrastText": "#fff"
  },
  "greenyellow": {
    "lighter": "rgb(230, 255, 192)",
    "light": "rgb(205, 255, 130)",
    "main": "#adff2f",
    "dark": "rgb(103, 153, 28)",
    "darker": "rgb(51, 76, 14)",
    "contrastText": "#fff"
  },
  "honeydew": {
    "lighter": "rgb(250, 255, 250)",
    "light": "rgb(246, 255, 246)",
    "main": "#f0fff0",
    "dark": "rgb(144, 153, 144)",
    "darker": "rgb(72, 76, 72)",
    "contrastText": "#fff"
  },
  "hotpink": {
    "lighter": "rgb(255, 210, 232)",
    "light": "rgb(255, 165, 210)",
    "main": "#ff69b4",
    "dark": "rgb(153, 63, 108)",
    "darker": "rgb(76, 31, 54)",
    "contrastText": "#fff"
  },
  "indianred ": {
    "lighter": "rgb(240, 206, 206)",
    "light": "rgb(225, 157, 157)",
    "main": "#cd5c5c",
    "dark": "rgb(123, 55, 55)",
    "darker": "rgb(61, 27, 27)",
    "contrastText": "#fff"
  },
  "indigo": {
    "lighter": "rgb(201, 178, 217)",
    "light": "rgb(147, 102, 180)",
    "main": "#4b0082",
    "dark": "rgb(45, 0, 78)",
    "darker": "rgb(22, 0, 39)",
    "contrastText": "#fff"
  },
  "ivory": {
    "lighter": "rgb(255, 255, 250)",
    "light": "rgb(255, 255, 246)",
    "main": "#fffff0",
    "dark": "rgb(153, 153, 144)",
    "darker": "rgb(76, 76, 72)",
    "contrastText": "#fff"
  },
  "khaki": {
    "lighter": "rgb(250, 247, 220)",
    "light": "rgb(246, 240, 186)",
    "main": "#f0e68c",
    "dark": "rgb(144, 138, 84)",
    "darker": "rgb(72, 69, 42)",
    "contrastText": "#fff"
  },
  "lavender": {
    "lighter": "rgb(247, 247, 253)",
    "light": "rgb(240, 240, 252)",
    "main": "#e6e6fa",
    "dark": "rgb(138, 138, 150)",
    "darker": "rgb(69, 69, 75)",
    "contrastText": "#fff"
  },
  "lavenderblush": {
    "lighter": "rgb(255, 250, 252)",
    "light": "rgb(255, 246, 249)",
    "main": "#fff0f5",
    "dark": "rgb(153, 144, 147)",
    "darker": "rgb(76, 72, 73)",
    "contrastText": "#fff"
  },
  "lawngreen": {
    "lighter": "rgb(215, 254, 178)",
    "light": "rgb(176, 253, 102)",
    "main": "#7cfc00",
    "dark": "rgb(74, 151, 0)",
    "darker": "rgb(37, 75, 0)",
    "contrastText": "#fff"
  },
  "lemonchiffon": {
    "lighter": "rgb(255, 253, 240)",
    "light": "rgb(255, 252, 225)",
    "main": "#fffacd",
    "dark": "rgb(153, 150, 123)",
    "darker": "rgb(76, 75, 61)",
    "contrastText": "#fff"
  },
  "lightblue": {
    "lighter": "rgb(230, 243, 247)",
    "light": "rgb(205, 231, 240)",
    "main": "#add8e6",
    "dark": "rgb(103, 129, 138)",
    "darker": "rgb(51, 64, 69)",
    "contrastText": "#fff"
  },
  "lightcoral": {
    "lighter": "rgb(250, 216, 216)",
    "light": "rgb(246, 178, 178)",
    "main": "#f08080",
    "dark": "rgb(144, 76, 76)",
    "darker": "rgb(72, 38, 38)",
    "contrastText": "#fff"
  },
  "lightcyan": {
    "lighter": "rgb(245, 255, 255)",
    "light": "rgb(236, 255, 255)",
    "main": "#e0ffff",
    "dark": "rgb(134, 153, 153)",
    "darker": "rgb(67, 76, 76)",
    "contrastText": "#fff"
  },
  "lightgoldenrodyellow": {
    "lighter": "rgb(253, 253, 241)",
    "light": "rgb(252, 252, 228)",
    "main": "#fafad2",
    "dark": "rgb(150, 150, 126)",
    "darker": "rgb(75, 75, 63)",
    "contrastText": "#fff"
  },
  "lightgrey": {
    "lighter": "rgb(241, 241, 241)",
    "light": "rgb(228, 228, 228)",
    "main": "#d3d3d3",
    "dark": "rgb(126, 126, 126)",
    "darker": "rgb(63, 63, 63)",
    "contrastText": "#fff"
  },
  "lightgreen": {
    "lighter": "rgb(221, 249, 221)",
    "light": "rgb(188, 244, 188)",
    "main": "#90ee90",
    "dark": "rgb(86, 142, 86)",
    "darker": "rgb(43, 71, 43)",
    "contrastText": "#fff"
  },
  "lightpink": {
    "lighter": "rgb(255, 233, 236)",
    "light": "rgb(255, 211, 217)",
    "main": "#ffb6c1",
    "dark": "rgb(153, 109, 115)",
    "darker": "rgb(76, 54, 57)",
    "contrastText": "#fff"
  },
  "lightsalmon": {
    "lighter": "rgb(255, 226, 215)",
    "light": "rgb(255, 198, 175)",
    "main": "#ffa07a",
    "dark": "rgb(153, 96, 73)",
    "darker": "rgb(76, 48, 36)",
    "contrastText": "#fff"
  },
  "lightseagreen": {
    "lighter": "rgb(188, 231, 229)",
    "light": "rgb(121, 208, 204)",
    "main": "#20b2aa",
    "dark": "rgb(19, 106, 102)",
    "darker": "rgb(9, 53, 51)",
    "contrastText": "#fff"
  },
  "lightskyblue": {
    "lighter": "rgb(219, 240, 253)",
    "light": "rgb(183, 225, 252)",
    "main": "#87cefa",
    "dark": "rgb(81, 123, 150)",
    "darker": "rgb(40, 61, 75)",
    "contrastText": "#fff"
  },
  "lightslategray": {
    "lighter": "rgb(214, 219, 224)",
    "light": "rgb(173, 183, 193)",
    "main": "#778899",
    "dark": "rgb(71, 81, 91)",
    "darker": "rgb(35, 40, 45)",
    "contrastText": "#fff"
  },
  "lightsteelblue": {
    "lighter": "rgb(231, 237, 245)",
    "light": "rgb(207, 219, 235)",
    "main": "#b0c4de",
    "dark": "rgb(105, 117, 133)",
    "darker": "rgb(52, 58, 66)",
    "contrastText": "#fff"
  },
  "lightyellow": {
    "lighter": "rgb(255, 255, 245)",
    "light": "rgb(255, 255, 236)",
    "main": "#ffffe0",
    "dark": "rgb(153, 153, 134)",
    "darker": "rgb(76, 76, 67)",
    "contrastText": "#fff"
  },
  "lime": {
    "lighter": "rgb(178, 255, 178)",
    "light": "rgb(102, 255, 102)",
    "main": "#00ff00",
    "dark": "rgb(0, 153, 0)",
    "darker": "rgb(0, 76, 0)",
    "contrastText": "#fff"
  },
  "limegreen": {
    "lighter": "rgb(193, 240, 193)",
    "light": "rgb(132, 225, 132)",
    "main": "#32cd32",
    "dark": "rgb(30, 123, 30)",
    "darker": "rgb(15, 61, 15)",
    "contrastText": "#fff"
  },
  "linen": {
    "lighter": "rgb(253, 250, 247)",
    "light": "rgb(252, 246, 240)",
    "main": "#faf0e6",
    "dark": "rgb(150, 144, 138)",
    "darker": "rgb(75, 72, 69)",
    "contrastText": "#fff"
  },
  "magenta": {
    "lighter": "rgb(255, 178, 255)",
    "light": "rgb(255, 102, 255)",
    "main": "#ff00ff",
    "dark": "rgb(153, 0, 153)",
    "darker": "rgb(76, 0, 76)",
    "contrastText": "#fff"
  },
  "maroon": {
    "lighter": "rgb(216, 178, 178)",
    "light": "rgb(178, 102, 102)",
    "main": "#800000",
    "dark": "rgb(76, 0, 0)",
    "darker": "rgb(38, 0, 0)",
    "contrastText": "#fff"
  },
  "mediumaquamarine": {
    "lighter": "rgb(209, 240, 229)",
    "light": "rgb(163, 225, 204)",
    "main": "#66cdaa",
    "dark": "rgb(61, 123, 102)",
    "darker": "rgb(30, 61, 51)",
    "contrastText": "#fff"
  },
  "mediumblue": {
    "lighter": "rgb(178, 178, 240)",
    "light": "rgb(102, 102, 225)",
    "main": "#0000cd",
    "dark": "rgb(0, 0, 123)",
    "darker": "rgb(0, 0, 61)",
    "contrastText": "#fff"
  },
  "mediumorchid": {
    "lighter": "rgb(234, 204, 241)",
    "light": "rgb(213, 153, 228)",
    "main": "#ba55d3",
    "dark": "rgb(111, 51, 126)",
    "darker": "rgb(55, 25, 63)",
    "contrastText": "#fff"
  },
  "mediumpurple": {
    "lighter": "rgb(222, 212, 243)",
    "light": "rgb(190, 169, 231)",
    "main": "#9370d8",
    "dark": "rgb(88, 67, 129)",
    "darker": "rgb(44, 33, 64)",
    "contrastText": "#fff"
  },
  "mediumseagreen": {
    "lighter": "rgb(196, 232, 212)",
    "light": "rgb(138, 209, 169)",
    "main": "#3cb371",
    "dark": "rgb(36, 107, 67)",
    "darker": "rgb(18, 53, 33)",
    "contrastText": "#fff"
  },
  "mediumslateblue": {
    "lighter": "rgb(215, 209, 249)",
    "light": "rgb(175, 164, 244)",
    "main": "#7b68ee",
    "dark": "rgb(73, 62, 142)",
    "darker": "rgb(36, 31, 71)",
    "contrastText": "#fff"
  },
  "mediumspringgreen": {
    "lighter": "rgb(178, 253, 224)",
    "light": "rgb(102, 252, 194)",
    "main": "#00fa9a",
    "dark": "rgb(0, 150, 92)",
    "darker": "rgb(0, 75, 46)",
    "contrastText": "#fff"
  },
  "mediumturquoise": {
    "lighter": "rgb(200, 241, 239)",
    "light": "rgb(145, 227, 224)",
    "main": "#48d1cc",
    "dark": "rgb(43, 125, 122)",
    "darker": "rgb(21, 62, 61)",
    "contrastText": "#fff"
  },
  "mediumvioletred": {
    "lighter": "rgb(238, 184, 218)",
    "light": "rgb(221, 114, 181)",
    "main": "#c71585",
    "dark": "rgb(119, 12, 79)",
    "darker": "rgb(59, 6, 39)",
    "contrastText": "#fff"
  },
  "midnightblue": {
    "lighter": "rgb(186, 186, 212)",
    "light": "rgb(117, 117, 169)",
    "main": "#191970",
    "dark": "rgb(15, 15, 67)",
    "darker": "rgb(7, 7, 33)",
    "contrastText": "#fff"
  },
  "mintcream": {
    "lighter": "rgb(252, 255, 253)",
    "light": "rgb(249, 255, 252)",
    "main": "#f5fffa",
    "dark": "rgb(147, 153, 150)",
    "darker": "rgb(73, 76, 75)",
    "contrastText": "#fff"
  },
  "mistyrose": {
    "lighter": "rgb(255, 246, 246)",
    "light": "rgb(255, 238, 237)",
    "main": "#ffe4e1",
    "dark": "rgb(153, 136, 135)",
    "darker": "rgb(76, 68, 67)",
    "contrastText": "#fff"
  },
  "moccasin": {
    "lighter": "rgb(255, 246, 232)",
    "light": "rgb(255, 238, 210)",
    "main": "#ffe4b5",
    "dark": "rgb(153, 136, 108)",
    "darker": "rgb(76, 68, 54)",
    "contrastText": "#fff"
  },
  "navajowhite": {
    "lighter": "rgb(255, 245, 230)",
    "light": "rgb(255, 235, 205)",
    "main": "#ffdead",
    "dark": "rgb(153, 133, 103)",
    "darker": "rgb(76, 66, 51)",
    "contrastText": "#fff"
  },
  "navy": {
    "lighter": "rgb(178, 178, 216)",
    "light": "rgb(102, 102, 178)",
    "main": "#000080",
    "dark": "rgb(0, 0, 76)",
    "darker": "rgb(0, 0, 38)",
    "contrastText": "#fff"
  },
  "oldlace": {
    "lighter": "rgb(254, 252, 247)",
    "light": "rgb(253, 249, 240)",
    "main": "#fdf5e6",
    "dark": "rgb(151, 147, 138)",
    "darker": "rgb(75, 73, 69)",
    "contrastText": "#fff"
  },
  "olive": {
    "lighter": "rgb(216, 216, 178)",
    "light": "rgb(178, 178, 102)",
    "main": "#808000",
    "dark": "rgb(76, 76, 0)",
    "darker": "rgb(38, 38, 0)",
    "contrastText": "#fff"
  },
  "olivedrab": {
    "lighter": "rgb(210, 221, 189)",
    "light": "rgb(166, 187, 123)",
    "main": "#6b8e23",
    "dark": "rgb(64, 85, 21)",
    "darker": "rgb(32, 42, 10)",
    "contrastText": "#fff"
  },
  "orange": {
    "lighter": "rgb(255, 228, 178)",
    "light": "rgb(255, 201, 102)",
    "main": "#ffa500",
    "dark": "rgb(153, 99, 0)",
    "darker": "rgb(76, 49, 0)",
    "contrastText": "#fff"
  },
  "orangered": {
    "lighter": "rgb(255, 199, 178)",
    "light": "rgb(255, 143, 102)",
    "main": "#ff4500",
    "dark": "rgb(153, 41, 0)",
    "darker": "rgb(76, 20, 0)",
    "contrastText": "#fff"
  },
  "orchid": {
    "lighter": "rgb(243, 212, 242)",
    "light": "rgb(232, 169, 230)",
    "main": "#da70d6",
    "dark": "rgb(130, 67, 128)",
    "darker": "rgb(65, 33, 64)",
    "contrastText": "#fff"
  },
  "palegoldenrod": {
    "lighter": "rgb(249, 248, 229)",
    "light": "rgb(244, 241, 204)",
    "main": "#eee8aa",
    "dark": "rgb(142, 139, 102)",
    "darker": "rgb(71, 69, 51)",
    "contrastText": "#fff"
  },
  "palegreen": {
    "lighter": "rgb(224, 253, 224)",
    "light": "rgb(193, 252, 193)",
    "main": "#98fb98",
    "dark": "rgb(91, 150, 91)",
    "darker": "rgb(45, 75, 45)",
    "contrastText": "#fff"
  },
  "paleturquoise": {
    "lighter": "rgb(231, 249, 249)",
    "light": "rgb(207, 244, 244)",
    "main": "#afeeee",
    "dark": "rgb(105, 142, 142)",
    "darker": "rgb(52, 71, 71)",
    "contrastText": "#fff"
  },
  "palevioletred": {
    "lighter": "rgb(243, 212, 222)",
    "light": "rgb(231, 169, 190)",
    "main": "#d87093",
    "dark": "rgb(129, 67, 88)",
    "darker": "rgb(64, 33, 44)",
    "contrastText": "#fff"
  },
  "papayawhip": {
    "lighter": "rgb(255, 250, 242)",
    "light": "rgb(255, 245, 229)",
    "main": "#ffefd5",
    "dark": "rgb(153, 143, 127)",
    "darker": "rgb(76, 71, 63)",
    "contrastText": "#fff"
  },
  "peachpuff": {
    "lighter": "rgb(255, 243, 234)",
    "light": "rgb(255, 232, 213)",
    "main": "#ffdab9",
    "dark": "rgb(153, 130, 111)",
    "darker": "rgb(76, 65, 55)",
    "contrastText": "#fff"
  },
  "peru": {
    "lighter": "rgb(240, 218, 197)",
    "light": "rgb(225, 181, 139)",
    "main": "#cd853f",
    "dark": "rgb(123, 79, 37)",
    "darker": "rgb(61, 39, 18)",
    "contrastText": "#fff"
  },
  "pink": {
    "lighter": "rgb(255, 236, 239)",
    "light": "rgb(255, 217, 223)",
    "main": "#ffc0cb",
    "dark": "rgb(153, 115, 121)",
    "darker": "rgb(76, 57, 60)",
    "contrastText": "#fff"
  },
  "plum": {
    "lighter": "rgb(244, 226, 244)",
    "light": "rgb(234, 198, 234)",
    "main": "#dda0dd",
    "dark": "rgb(132, 96, 132)",
    "darker": "rgb(66, 48, 66)",
    "contrastText": "#fff"
  },
  "powderblue": {
    "lighter": "rgb(231, 245, 247)",
    "light": "rgb(207, 236, 240)",
    "main": "#b0e0e6",
    "dark": "rgb(105, 134, 138)",
    "darker": "rgb(52, 67, 69)",
    "contrastText": "#fff"
  },
  "purple": {
    "lighter": "rgb(216, 178, 216)",
    "light": "rgb(178, 102, 178)",
    "main": "#800080",
    "dark": "rgb(76, 0, 76)",
    "darker": "rgb(38, 0, 38)",
    "contrastText": "#fff"
  },
  "rebeccapurple": {
    "lighter": "rgb(209, 193, 224)",
    "light": "rgb(163, 132, 193)",
    "main": "#663399",
    "dark": "rgb(61, 30, 91)",
    "darker": "rgb(30, 15, 45)",
    "contrastText": "#fff"
  },
  "red": {
    "lighter": "rgb(255, 178, 178)",
    "light": "rgb(255, 102, 102)",
    "main": "#ff0000",
    "dark": "rgb(153, 0, 0)",
    "darker": "rgb(76, 0, 0)",
    "contrastText": "#fff"
  },
  "rosybrown": {
    "lighter": "rgb(234, 221, 221)",
    "light": "rgb(214, 187, 187)",
    "main": "#bc8f8f",
    "dark": "rgb(112, 85, 85)",
    "darker": "rgb(56, 42, 42)",
    "contrastText": "#fff"
  },
  "royalblue": {
    "lighter": "rgb(198, 210, 246)",
    "light": "rgb(141, 165, 237)",
    "main": "#4169e1",
    "dark": "rgb(39, 63, 135)",
    "darker": "rgb(19, 31, 67)",
    "contrastText": "#fff"
  },
  "saddlebrown": {
    "lighter": "rgb(220, 199, 184)",
    "light": "rgb(185, 143, 113)",
    "main": "#8b4513",
    "dark": "rgb(83, 41, 11)",
    "darker": "rgb(41, 20, 5)",
    "contrastText": "#fff"
  },
  "salmon": {
    "lighter": "rgb(253, 216, 212)",
    "light": "rgb(252, 178, 170)",
    "main": "#fa8072",
    "dark": "rgb(150, 76, 68)",
    "darker": "rgb(75, 38, 34)",
    "contrastText": "#fff"
  },
  "sandybrown": {
    "lighter": "rgb(251, 227, 207)",
    "light": "rgb(248, 200, 159)",
    "main": "#f4a460",
    "dark": "rgb(146, 98, 57)",
    "darker": "rgb(73, 49, 28)",
    "contrastText": "#fff"
  },
  "seagreen": {
    "lighter": "rgb(192, 220, 204)",
    "light": "rgb(129, 185, 154)",
    "main": "#2e8b57",
    "dark": "rgb(27, 83, 52)",
    "darker": "rgb(13, 41, 26)",
    "contrastText": "#fff"
  },
  "seashell": {
    "lighter": "rgb(255, 252, 249)",
    "light": "rgb(255, 249, 244)",
    "main": "#fff5ee",
    "dark": "rgb(153, 147, 142)",
    "darker": "rgb(76, 73, 71)",
    "contrastText": "#fff"
  },
  "sienna": {
    "lighter": "rgb(226, 203, 192)",
    "light": "rgb(198, 151, 129)",
    "main": "#a0522d",
    "dark": "rgb(96, 49, 27)",
    "darker": "rgb(48, 24, 13)",
    "contrastText": "#fff"
  },
  "silver": {
    "lighter": "rgb(236, 236, 236)",
    "light": "rgb(217, 217, 217)",
    "main": "#c0c0c0",
    "dark": "rgb(115, 115, 115)",
    "darker": "rgb(57, 57, 57)",
    "contrastText": "#fff"
  },
  "skyblue": {
    "lighter": "rgb(219, 240, 249)",
    "light": "rgb(183, 225, 243)",
    "main": "#87ceeb",
    "dark": "rgb(81, 123, 141)",
    "darker": "rgb(40, 61, 70)",
    "contrastText": "#fff"
  },
  "slateblue": {
    "lighter": "rgb(210, 205, 240)",
    "light": "rgb(165, 156, 225)",
    "main": "#6a5acd",
    "dark": "rgb(63, 54, 123)",
    "darker": "rgb(31, 27, 61)",
    "contrastText": "#fff"
  },
  "slategray": {
    "lighter": "rgb(212, 216, 221)",
    "light": "rgb(169, 178, 188)",
    "main": "#708090",
    "dark": "rgb(67, 76, 86)",
    "darker": "rgb(33, 38, 43)",
    "contrastText": "#fff"
  },
  "snow": {
    "lighter": "rgb(255, 253, 253)",
    "light": "rgb(255, 252, 252)",
    "main": "#fffafa",
    "dark": "rgb(153, 150, 150)",
    "darker": "rgb(76, 75, 75)",
    "contrastText": "#fff"
  },
  "springgreen": {
    "lighter": "rgb(178, 255, 216)",
    "light": "rgb(102, 255, 178)",
    "main": "#00ff7f",
    "dark": "rgb(0, 153, 76)",
    "darker": "rgb(0, 76, 38)",
    "contrastText": "#fff"
  },
  "steelblue": {
    "lighter": "rgb(199, 217, 232)",
    "light": "rgb(144, 180, 210)",
    "main": "#4682b4",
    "dark": "rgb(42, 78, 108)",
    "darker": "rgb(21, 39, 54)",
    "contrastText": "#fff"
  },
  "tan": {
    "lighter": "rgb(241, 232, 220)",
    "light": "rgb(228, 210, 186)",
    "main": "#d2b48c",
    "dark": "rgb(126, 108, 84)",
    "darker": "rgb(63, 54, 42)",
    "contrastText": "#fff"
  },
  "teal": {
    "lighter": "rgb(178, 216, 216)",
    "light": "rgb(102, 178, 178)",
    "main": "#008080",
    "dark": "rgb(0, 76, 76)",
    "darker": "rgb(0, 38, 38)",
    "contrastText": "#fff"
  },
  "thistle": {
    "lighter": "rgb(243, 235, 243)",
    "light": "rgb(231, 216, 231)",
    "main": "#d8bfd8",
    "dark": "rgb(129, 114, 129)",
    "darker": "rgb(64, 57, 64)",
    "contrastText": "#fff"
  },
  "tomato": {
    "lighter": "rgb(255, 208, 199)",
    "light": "rgb(255, 161, 144)",
    "main": "#ff6347",
    "dark": "rgb(153, 59, 42)",
    "darker": "rgb(76, 29, 21)",
    "contrastText": "#fff"
  },
  "turquoise": {
    "lighter": "rgb(197, 245, 240)",
    "light": "rgb(140, 236, 226)",
    "main": "#40e0d0",
    "dark": "rgb(38, 134, 124)",
    "darker": "rgb(19, 67, 62)",
    "contrastText": "#fff"
  },
  "violet": {
    "lighter": "rgb(249, 217, 249)",
    "light": "rgb(244, 180, 244)",
    "main": "#ee82ee",
    "dark": "rgb(142, 78, 142)",
    "darker": "rgb(71, 39, 71)",
    "contrastText": "#fff"
  },
  "wheat": {
    "lighter": "rgb(252, 245, 232)",
    "light": "rgb(249, 235, 209)",
    "main": "#f5deb3",
    "dark": "rgb(147, 133, 107)",
    "darker": "rgb(73, 66, 53)",
    "contrastText": "#fff"
  },
  "white": {
    "lighter": "rgb(255, 255, 255)",
    "light": "rgb(255, 255, 255)",
    "main": "#ffffff",
    "dark": "rgb(153, 153, 153)",
    "darker": "rgb(76, 76, 76)",
    "contrastText": "#fff"
  },
  "whitesmoke": {
    "lighter": "rgb(252, 252, 252)",
    "light": "rgb(249, 249, 249)",
    "main": "#f5f5f5",
    "dark": "rgb(147, 147, 147)",
    "darker": "rgb(73, 73, 73)",
    "contrastText": "#fff"
  },
  "yellow": {
    "lighter": "rgb(255, 255, 178)",
    "light": "rgb(255, 255, 102)",
    "main": "#ffff00",
    "dark": "rgb(153, 153, 0)",
    "darker": "rgb(76, 76, 0)",
    "contrastText": "#fff"
  },
  "yellowgreen": {
    "lighter": "rgb(224, 240, 193)",
    "light": "rgb(194, 225, 132)",
    "main": "#9acd32",
    "dark": "rgb(92, 123, 30)",
    "darker": "rgb(46, 61, 15)",
    "contrastText": "#fff"
  }
};


/* eslint-enable */


export default function palette(themeMode) {
  const light = {
    ...COMMON,
    ...allColors,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      ...COMMON.action,
      active: GREY[600],
      tablerow_odd1: "rgb(45, 145, 245)",
      tablerow_odd: alpha("rgb(45, 145, 245)", 0.16),
    },
  };

  const dark = {
    ...COMMON,
    ...allColors,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: alpha(GREY[500], 0.16),
    },
    action: {
      ...COMMON.action,
      active: GREY[500],
      tablerow_odd: alpha("rgb(25, 30, 75)", 0.16),
    },
  };

  return themeMode === 'light' ? light : dark;
}
