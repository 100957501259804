import PropTypes from 'prop-types';
import { useLocation, matchPath } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';

// config
import { HEADER, NAV } from '../../../config-global';
// components
// import Label from '../../../components/label';
import Logo from '../../../components/logo';
import Iconify from '../../../components/iconify';
import { useSettingsContext } from '../../../components/settings';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
import navConfig from '../../../routes/config-navigation';
// import navConfig from '../nav/config-navigation';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { translate } = useLocales();

  const { themeLayout } = useSettingsContext();

  const { pathname } = useLocation();

  const pageTitle = translate(navConfig[0].items.find(({ path }) =>  matchPath({ path, end: true }, pathname) )?.title ?? " ");

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && 
      <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
      maxWidth="150px"
      spacing={{ xs: 0.5, sm: 1.5}}
    >
      <Logo sx={{ mx: 2.5 }} />
      </Stack>
      }

      {!isDesktop && (
        <IconButton name="OpenNav" onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >

        {isDesktop && isNavHorizontal && <NavSectionHorizontal data={navConfig} />}
        {isDesktop && !isNavHorizontal && <Typography variant="h3" color="primary" >{pageTitle}</Typography>}
        {/* 
        {isDesktop && <Typography><Label>{pageTitle}</Label></Typography>}
      {isDesktop && isNavHorizontal ? <Label>horizontal</Label> : <Label >vertical</Label>}
      <Searchbar /> 
      */}
      </Stack>
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        maxWidth="150px"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >

        <LanguagePopover />


        <NotificationsPopover />

        {/* 
        <ContactsPopover /> 
        */}

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
