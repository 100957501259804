import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState, forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';

// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
// import { _notifications } from '../../../_mock/arrays';
// components
import { InlineIcon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
// utils
import { fToNow } from '../../../utils/formatTime';
// _mock_
import { useAPI } from "../../../context/apiContext";
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { translate } = useLocales();

  const [openPopover, setOpenPopover] = useState(null);
  const { notifications, setNotifications } = useAPI();

  //  const [notifications, setNotifications] = useState(_notifications);
  // console.log(JSON.stringify(notifications));
  const notificationsUnRead = notifications.filter((item) => item.isUnRead === true);
  const notificationsRead = notifications.filter((item) => item.isUnRead === false);
  const totalUnRead = notificationsUnRead.length;
  const totalRead = notificationsRead.length;

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    //  console.log (`mark all as read: ${JSON.stringify(notifications)}`);
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };
  const handleMarkAsRead = (id) => {
    //  console.log (`mark as read: ${id}`);
    setNotifications(notifications.map((notification) => (notification.id === id ? { ...notification, isUnRead: !notification.isUnRead, } : notification)));
  };
  const handleRemoveNotification = (id) => {
    //  console.log (`mark as read: ${id}`);
    setNotifications(notifications.filter((notification) => (notification.id !== id)));
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 360, p: 0 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1"> {translate("Notifications")} </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {translate("You have")} {totalUnRead}  {translate("unread messages")}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {totalUnRead > 0 &&
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, fontStyle: 'italic', typography: 'caption' }}>
                  {translate("New_messages")}
                </ListSubheader>
              }
            >
              {notificationsUnRead.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} onClick={() => handleMarkAsRead(notification.id)} />
              ))}
            </List>
          }
          {totalRead > 0 &&
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, fontStyle: 'italic', typography: 'caption' }}>
                  {translate("Before that")}                  
                </ListSubheader>
              }
            >
              {notificationsRead.filter((item) => item.isUnRead === false).map((notification) => (
                <NotificationItem key={notification.id} notification={notification} onClick={() => handleMarkAsRead(notification.id)} onRemove={() => handleRemoveNotification(notification.id)} />
              ))}
            </List>
          }
        </Scrollbar>

        {/*
        <Divider sx={{ borderStyle: 'dashed' }} />

         <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
 */}      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    avatar: PropTypes.node,
    type: PropTypes.string,
    isUnRead: PropTypes.bool,
    createdAt: PropTypes.instanceOf(Date),
  }),
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};



function NotificationItem({ notification, onClick, onRemove }) {
  const theme = useTheme();

  // const { avatar, title } = renderContent(notification);
  // console.log(JSON.stringify(notification));
  const myIconify = (icon, color = 'blue') => {
    if (!icon) {
      console.log("no item");
      return null;
    }
    if (icon.includes(":"))
      return (<Button color={color} sx={{ width: 1, height: 1, ml: -1.5 }} endIcon={<Iconify icon={icon} sx={{ width: 1, height: 1, }} />} />);
    //    if (icon.includes(":"))
    //      return (<Iconify color={color} icon={icon} sx={{ width: 1, height: 1, color }} />);
    return (<SvgColor color={color} src={`/assets/icons/${icon}.svg`} sx={{ width: 1, height: 1 }} />);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const trimString = (s, len = 100) => s.length > len ? `${s.slice(0, len)}...` : s;

  return (
    <ListItemButton onClick={onClick}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{myIconify(notification?.icon)}</Avatar>
      </ListItemAvatar>

      <ListItemText
        disableTypography
        primary={
          <>
            <Typography variant="subtitle2">
              {notification.title}
            </Typography>
            <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                {trimString(notification.description)}
              </Typography>
            </div>
          </>
        }
        secondary={
          <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
            <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} />
            <Typography variant="caption">{fToNow(notification.createdAt)}</Typography>
            {onRemove && <Button
              color="error"
              sx={{ flexShrink: 0, mr: 0, marginLeft: "auto" }}
              onClick={(e) => { stopPropagation(e); onRemove(notification.id) }}
              endIcon={<Iconify width={16} icon="eva:trash-2-outline" />}
            />
            }
          </Stack>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------
/*
function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
*/