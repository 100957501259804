// routes
import { PATH_DASHBOARD } from './routes/paths';
// import { AppConfigurationClient } from "@azure/app-configuration"
// const client = new AppConfigurationClient('PASTE_CONNECTION_STRING'); 



// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_API_KEY_API = process.env.REACT_APP_HOST_API_KEY_API || '';
export const HOST_API_access_token = process.env.REACT_APP_access_token || '';


export const OAUTH2 = {
  clientId: process.env.REACT_APP_clientId,
  redirectUri1: process.env.REACT_APP_redirectUri,
  redirectUri2: process.env.REACT_APP_REDIRECTURI,  
  redirectUri: window.location.origin,
  scope: process.env.REACT_APP_scope,
  test1: process.env.REACT_APP_TEST1,
  test2: process.env.REACT_APP_TEST2,  
  test3: window.location.origin,   
  test4ra: process.env.REACT_APP_TEST4,   
  test4: process.env.TEST4,   
};



export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAP_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.dashboard;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88, // 88,
  H_DASHBOARD_DESKTOP: 92, // + 50, // 62,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32, // - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
