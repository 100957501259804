import axios from 'axios';
// config
import { HOST_API_KEY } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

// import axios from 'axios';
// axios.defaults.baseURL = 'http://localhost:1010/'
// axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
// export default axios;



axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;




/* 
const axios = require('axios');
const axiosApiInstance = axios.create();

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    const value = await redisClient.get(rediskey)
    const keys = JSON.parse(value)
    config.headers = { 
      'Authorization': `Bearer ${keys.access_token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 403 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return axiosApiInstance(originalRequest);
  }
  return Promise.reject(error);
});
 */




/* 


import Vue from 'vue';
import Axios from 'axios';

import AuthProxy from '@/proxies/AuthProxy';
import store from '@/store';

Axios.defaults.baseURL = window.config.connections.rest;
Axios.defaults.headers.common.Accept = 'application/json';
Axios.defaults.withCredentials = true;

let isRefreshingToken = false;
let subscribers = [];

const onTokenFetched = () => {
  subscribers = subscribers.filter(cb => cb());
};

const addSubscriber = cb => {
  subscribers.push(cb);
};
Axios.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error;
    const originalRequest = config;

    if (response.status === 401) {
      if (!isRefreshingToken) {
        isRefreshingToken = true;

        const hasRefreshToken = document.cookie.indexOf('refreshtoken=') && localStorage.getItem('user');

        if (!hasRefreshToken) {
          store.dispatch('auth/logout');
        } else {
          new AuthProxy()
            .refresh()
            .then(response => {
              isRefreshingToken = false;
              store.dispatch('auth/refresh', response);
              onTokenFetched();
            })
            .catch(() => {
              isRefreshingToken = false;
              store.dispatch('auth/logout');
            });
        }
      } else {
        return error;
      }

      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(() => {
          resolve(Axios(originalRequest));
        });
      });

      return retryOriginalRequest;
    } else if (response.status === 403) {
      Vue.router.push('/error?errorcode=forbidden');
    }

    return Promise.reject(error);
  }
);

Vue.$http = Axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return Axios;
  }
});
 */


/*
  login ({ username, password }) {
    const payload = {
      loginName: username,
      password: password
    };
    return this.submit('post', '/auth', payload);
  }

  refresh () {
    return this.submit('post', '/auth/refresh', {});
  }

  logout () {
    return this.submit('post', '/auth/logout', {});
  }
  
  submit (requestType, url, data = null) {
    return new Promise((resolve, reject) => {
      Vue.$http[requestType](url + this.getParameterString(), data)
        .then((response) => {
          resolve(response.data);
        })
        .catch(({ response }) => {
          if (response) {
            reject(response.data);
          } else {
            reject(new Error('REST Client error'));
          }
        });
    });
  }


  */