// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `Nederlands`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hoi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,

  // mine
  Dense: 'Dichter',

  // menu
  Home: `Thuis`,
  Analytics: 'DQ Cijfers',
  Dashboard: 'DQ Cijfers',
  Issues: `Incidenten`,
  Rules: `Controles`,
  Jobs: `Taken`,
  Reports: 'Rapporten',
  //  Management: `Beheer`,
  //  Profile: `Profiel`,
  //  Settings: `Instellingen`,

  // account
  Login: `Inloggen`,
  Logout: `Uitloggen`,

  // analytics
  Refresh: `Vernieuwen`,
  Last_Month: `Vorige maand`,
  This_Month: `Deze maand`,
  Open_issues: `Open incidenten`,

Handled_issues: `Opgeloste incidenten`,
Created_issues: `Nieuwe incidenten`,
Handled: `Afgehandeld`,
Average_duration: `Duur tov deadline`,

  Handled_issues_this_month: `Opgeloste incidenten deze maand`,
  Created_issues_this_month: `Nieuwe incidenten deze maand`,
  // Created: `Nieuw`,
  Open: `Open`,

  Issues_per_day: `Incidenten per dag`,
  Issues_per_rule: `Incidenten per controle`,

  // filter
  Filters: `Filters`,
  issues_found: `incidenten gevonden.`,
  Clear: `wissen`,
  high: 'hoog',
  medium: 'gemiddeld',
  low: 'laag',
  High: 'Hoog',
  Medium: 'Gemiddeld',
  Low: 'Laag',
  from_: 'van ',
  to_: 't/m ',

  
  
  // issue
  Rule: `Controle`,
  Created: `Aangemaakt`,
  Status: `Status`,
  Handler: `Behandelaar`,
  Priority: `Prioriteit`,
  Deadline: `Deadline`,
  Duration: `Duur`,
  Actions: `Acties`,
  //  Job: `Taak`,

  // issue row
  Description: 'Omschrijving',
  Details: `Details`,
  History: `Geschiedenis`,

  DQC_ID: `Orig ID`,
  DQC_Value: `Orig waarde`,
  DQC_Message: `Orig melding`,
  Comment: `Commentaar`,

  Change_Date: `Aangepast per`,
  From_Status: `Van status`,
  To_Status: `Tot status`,
  Author: `Door`,
  // status
  Assess: 'Ter beoordeling',
  Approve: 'Goedkeuren',
  Confirmed: 'Bevestigd',
  Corrected: 'Gecorrigeerd',
  Confirm_rejection: 'Bevestig afkeuren correctie',
  Rejected: 'Voorgestelde correctie geweigerd',
  Confirm_exception: ' Bevestig uitzondering',
  Excepted: 'Uitzondering bevestigd',
  Selfcorrected: 'Zelf gecorrigeerd',
  Skipped: 'Overgeslagen',
  Error: 'Fout',
  Correct: 'Correct',
  
  // actions
  Change_handler: `Behandelaar aanpasen`,
  Propose_remediation: 'Stel correctie voor',
  Propose_exception: 'Stel uitzondering op de regel voor',
  Propose_rejection: 'Stel voor deze niet te corrigeren',
  Approve_remediation: 'Keur voorgestelde correctie goed',
  Reject_remediation: 'Keur voorgestelde correctie af',
  Undo_remediation: 'Maak voorgestelde correctie ongedaan ',
  Approve_rejection: 'Keur voorstel om niet te corrigeren goed',
  Reject_rejection: 'Keur voorstel om niet te corrigeren af',
  Undo_rejection: 'Maak voorstel om niet te corrigeren ongedaan',
  Approve_exception: 'Keur uitzondering op de regel goed',
  Reject_exception: 'Keur uitzondering op de regel af',
  Undo_exception: 'Maak uitzondering op de regel ongedaan',

  Execute_rule: 'Controle uitvoeren',

  Notifications: 'Notificaties',
  You_have: 'U heeft',
  unread_messages: 'ongelezen berichten',
  Mark_all_as_read: 'Alle berichten lezen',
  New_messages: 'Nieuwe berichten',
  Before_that: 'Gelezen berichten',
  

  Excepted1: 'Excepted',

};

export default en;
