import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// msal
import { useMsal, useIsAuthenticated } from '@azure/msal-react';

// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import { useAPI } from "../../../context/apiContext";

// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import LanguagePopover from './LanguagePopover';

import { useLocales } from '../../../locales';

import { loginRequest } from "../../../auth/authConfig";

import { OAUTH2 } from '../../../config-global';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/dashboard/user/four',
  },
  {
    label: 'Profile',
    linkTo: '/dashboard/user/five',
  },
  {
    label: 'Settings',
    linkTo: '/dashboard/user/six',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { translate } = useLocales();

  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState(null);
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };
  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();

  const { users, setUsers, user: ADuser, onChangeUser, apiToken, onChangeApiToken } = useAPI();

  /*
    const addUser = useCallback((id, username,email) => {
      if (accounts && accounts[0]) {
        users.push({ id, username, email });
  //      users.push({ id: 101, username: accounts[0]?.name, email: accounts[0]?.username });
        setUsers(users);
      }
    }, [accounts, users, setUsers])
  */

  useEffect(() => {
    //    console.log("accounts[0]");
    //    console.log(accounts[0]);
    // if (ADuser.code !== accounts[0]?.username )
    onChangeUser(accounts[0]?.username);

  }, [accounts, onChangeUser]);


  const [accessToken, setAccessToken] = useState('anon');
  useEffect(() => {
    onChangeApiToken(accessToken);
  }, [accessToken, onChangeApiToken]);

  useEffect(() => {
    // https://stackoverflow.com/questions/72419373/aadsts65001-the-user-or-administrator-has-not-consented-to-use-the-application
    if (accounts && isAuthenticated) {
      console.log(`name: ${accounts && accounts[0]?.name}`);
      console.log(`username: ${accounts && accounts[0]?.username}`);

      instance.acquireTokenSilent({
        scopes: [OAUTH2.scope],
        account: accounts[0],
      }).then((response) => {
        if (response) {
          console.log(`Login completed: ${response.accessToken}`);
          localStorage.setItem("auth2", response.accessToken);
          setAccessToken(response.accessToken);
        }
      });
    }
    else {
      console.log("Logout completed: anon");
      localStorage.setItem("auth2", "anon");
    }
  }, [isAuthenticated, accounts, instance, setAccessToken]);


  if (user) if (!isAuthenticated) { user.displayName = "Anonymous"; user.photoURL = "assets/shape_avatar.svg"; };
  const user1 = user ?? { displayName: "Anonymous", photoURL1: "/assets/shape_avatar.svg" };


  const handleSignin = async () => {
    try {

      await instance.loginPopup(loginRequest)
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to sign in!', { variant: 'error' });
    }
  };
  const handleSignout = async () => {
    try {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
      //      logout();
      //      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to sign out!', { variant: 'error' });
    }
  };

  /*
  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };
  */

  const handleClickItem = (path) => {
    handleClosePopover();
    navigate(path);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {isAuthenticated ?
          <CustomAvatar name={ADuser?.suffix} color={ADuser?.color} alt={ADuser?.firstname} name2={accounts[0]?.name} alt1={accounts[0]?.name} name1={accounts[0]?.name} />
          :
          <CustomAvatar src={user1?.photoURL} alt={user1?.displayName} name={user1?.displayName} />
        }
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {ADuser?.firstname} {ADuser?.lastname} {!isAuthenticated && "Anonymous"}
            {/*             {isAuthenticated ? accounts && accounts[0]?.name : user1?.displayName} */}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {ADuser?.email}
            ADUser: {JSON.stringify(ADuser)}
            {/*             {isAuthenticated ? accounts && accounts[0]?.username : user1?.email} */}
          </Typography>
        </Box>

        {/* 
        <Box sx={{ my: 0.0, px: 2.5 }}>
          <LanguagePopover />
        </Box>


        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem key={ADuser?.id} > {ADuser?.id}-{ADuser?.suffix} - {ADuser?.lastname} - {ADuser?.email}  </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
          <MenuItem key='apiToken' > {apiToken}  </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        {users.map(u => (
          <MenuItem key={u.id} > {u.suffix} {u.lastname} - {u.code}  </MenuItem>
        ))}
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {`${translate(option.label)}`}
            </MenuItem>
          ))}
        </Stack>

 */}
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
          11:11
        </MenuItem>
        {/*
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
          redirectUri1: {OAUTH2.redirectUri1}
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
          redirectUri: {OAUTH2.redirectUri}
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
        window.location.href: {window.location.href}
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
        window.location.pathname: {window.location.pathname}
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
          test3: {OAUTH2.test3}
        </MenuItem>
        <MenuItem sx={{ m: 1 }} onClick={() => console.log(JSON.stringify(accounts && accounts[0]))} >
        window.location.origin: {window.location.origin}
        </MenuItem>
*/}
        <Divider sx={{ borderStyle: 'dashed' }} />

        {isAuthenticated ?
          <MenuItem onClick={handleSignout} sx={{ m: 1 }}>
            {`${translate('Sign Out')}`}
          </MenuItem>
          :
          <MenuItem onClick={handleSignin} sx={{ m: 1 }}>
            {`${translate('Sign In')}`}
          </MenuItem>
        }
      </MenuPopover>
    </>
  );
}
