// routes
import { PATH_DASHBOARD } from './paths';
// components
import SvgColor from '../components/svg-color';
import Iconify from '../components/iconify';

// import { useLocales } from '../locales';

// ----------------------------------------------------------------------

const icon = (name) => {
  if (name.includes(":"))
    return (<Iconify icon={name} sx={{ width: 1, height: 1 }} />);
  return (<SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />);
};

const ICONS = {
  dashboard: icon('ph:list-numbers'),
  analytics: icon('ph:list-numbers'),
  issues: icon('fluent:book-exclamation-mark-24-regular'),
  rules: icon('ic_rules'),
  jobs: icon('carbon:task-tools'),
  reports: icon('ic_reports'),

  /*
    user1: icon('ic_user'),
    analytics1: icon('ic_analytics'),
    issues1: icon('ic_issues'),
    rules1: icon('ic_rules'),
    jobs1: icon('ic_jobs'),
    reports1: icon('ic_reports'),
   */
};



const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard, },
      { title: 'Issues', path: PATH_DASHBOARD.issues, icon: ICONS.issues, authenticated: 1 },
      { title: 'Rules', path: PATH_DASHBOARD.rules, icon: ICONS.rules, authenticated: 1 },
      { title: 'Jobs', path: PATH_DASHBOARD.jobs, icon: ICONS.jobs, authenticated: 1 },
      { title: 'Reports', path: PATH_DASHBOARD.reports, icon: ICONS.reports },
    ],
  },
];




export default navConfig;
