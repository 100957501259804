// https://www.w3schools.com/colors/colors_names.asp

// Propose Remediation - Stel correctie voor - icoontje: vraagteken, kleur blauw
// Propose Exception - Stel uitzondering op de regel voor - icoontje vraagteken, kleur geel
// Propose Rejection - Stel voor deze niet te corrigeren - icoontje vraagteken, kleur rood
// Approve Remediation - Keur voorgestelde correctie goed - icoontje checkmark, kleur blauw
// Reject Remediation - Keur voorgestelde correctie af - icoontje kruisje, kleur blauw
// Undo Remediation - Maak voorgestelde correctie ongedaan - icoontje iconoir:undo-circle, kleur blauw
// Approve Rejection - Keur voorstel om niet te corrigeren goed - icoontje checkmark, kleur rood
// Reject Rejection - Keur voorstel om niet te corrigeren af - icoontje kruisje, kleur rood
// Undo Rejection - Maak voorstel om niet te corrigeren ongedaan - icoontje iconoir:undo-circle, kleur rood
// Approve Exception - Keur uitzondering op de regel goed - icoontje checkmark, kleur geel
// Reject Exception - Keur uitzondering op de regel af - icoontje kruisje, kleur geel
// Undo Exception- Maak uitzondering op de regel ongedaan - icoontje iconoir:undo-circle, kleur geel


export const _actionsData = [
  {
    "name": "Propose remediation",
    "nameNL": "Propose remediation",
    "from_status_id": 1,
    "to_status_id": 7,
    "color": 'preset-blue',
    "icon": "ant-design:right-circle-outlined"
  },
  {
    "name": "Propose exception",
    "from_status_id": 1,
    "to_status_id": 9,
    "color": 'warning',
    "icon": "bi:exclamation-circle"
  },
  {
    "name": "Propose rejection",
    "from_status_id": 1,
    "to_status_id": 8,
    "color": 'error',
    "icon": "fluent:question-circle-16-regular"
  },
  {
    "name": "Approve remediation",
    "from_status_id": 7,
    "to_status_id": 2,
    "color": 'preset-blue',
    "icon": "ant-design:check-circle-outlined"
  },
  {
    "name": "Reject remediation",
    "from_status_id": 7,
    "to_status_id": 1,
    "color": 'preset-blue',
    "icon": "ant-design:close-circle-outlined"
  },

  {
    "name": "Undo remediation",
    "from_status_id": 2,
    "to_status_id": 1,
    "color": 'preset-blue',
    "icon": "ion:arrow-undo-circle-outline"
  },
  {
    "name": "Approve rejection",
    "from_status_id": 8,
    "to_status_id": 5,
    "color": 'error',
    "icon": "ant-design:check-circle-outlined"
  },
  {
    "name": "Reject rejection",
    "from_status_id": 8,
    "to_status_id": 1,
    "color": 'error',
    "icon": "ant-design:close-circle-outlined"
  },
  {
    "name": "Undo rejection",
    "from_status_id": 5,
    "to_status_id": 1,
    "color": 'error',
    "icon": "ion:arrow-undo-circle-outline"
  },
  {
    "name": "Approve exception",
    "from_status_id": 9,
    "to_status_id": 6,
    "color": 'warning',
    "icon": "ant-design:check-circle-outlined"
  },
  {
    "name": "Reject exception",
    "from_status_id": 9,
    "to_status_id": 1,
    "color": 'warning',
    "icon": "ant-design:close-circle-outlined"
  },
  {
    "name": "Undo exception",
    "from_status_id": 6,
    "to_status_id": 1,
    "color": 'warning',
    "icon": "ion:arrow-undo-circle-outline"
  }
];

export const _statusData = [
  /*     {
        "status_id": 0,
        "code": "Start",
        "name": "Start",
        "flow_id": "1",
        "handler_id": "0"
      }, */
  {
    "status_id": 1,
    "code": "Assess",
    "name": "Assess",
    "nameNL": "Assess",
    "flow_id": "1",
    "handler_id": "1001",
    "color": 'info',
    "icon": "ion:arrow-undo-circle-outline"
  },
  {
    "color": 'warning',
    "status_id": 7,
    "code": "Approve",
    "name": "Approve",
    "flow_id": "1",
    "handler_id": "1007",
  },
  {
    "color": 'success',
    "status_id": 2,
    "code": "Confirmed",
    "name": "Confirmed",
    "flow_id": "1",
    "handler_id": "1002",
  },
  {
    "color": 'default',
    "status_id": 3,
    "code": "Corrected",
    "name": "Corrected",
    "flow_id": "1",
    "handler_id": "0"
  },
  {
    "color": 'success',
    "status_id": 8,
    "code": "Confirmrejection",
    "name": "Confirm rejection",
    "flow_id": "1",
    "handler_id": "1008",
  },
  {
    "color": 'default',
    "status_id": 5,
    "code": "Rejected",
    "name": "Rejected",
    "flow_id": "1",
    "handler_id": "0",
  },
  {
    "color": 'success',
    "status_id": 9,
    "code": "Confirmexception",
    "name": "Confirm exception",
    "flow_id": "1",
    "handler_id": "1009",
  },
  {
    "color": 'default',
    "status_id": 6,
    "code": "Excepted",
    "name": "Excepted",
    "flow_id": "1",
    "handler_id": "0",
  },
  {
    "color": 'default',
    "status_id": 4,
    "code": "Selfcorrected",
    "name": "Selfcorrected",
    "flow_id": "1",
    "handler_id": "0"
  },
  {
    "color": 'default',
    "status_id": 10,
    "code": "Skipped",
    "name": "Skipped",
    "flow_id": "1",
    "handler_id": "0"
  },
  {
    "color": 'default',
    "status_id": 11,
    "code": "Error",
    "name": "Error",
    "flow_id": "1",
    "handler_id": "0"
  },
  {
    "color": 'default',
    "status_id": 12,
    "code": "Correct",
    "name": "Correct",
    "flow_id": "1",
    "handler_id": "0"
  }

];

export const _priorityData = [
  { id: 1, value: 1, name: 'high', label: 'High', color: 'error' },
  { id: 2, value: 2, name: 'medium' , label: 'Medium', color: 'warning' },
  { id: 3, value: 3, name: 'low', label: 'Low', color: 'info' },
];

export const _deadlineRangeData = [
  { id: 1, value1: null, value2:-30*24*3600, name: 'late > 30 days',   },
  { id: 2, value1: -30*24*3600, value2:-7*24*3600, name: 'late > 7 days',   },
  { id: 3, value1: -7*24*3600, value2:-1*24*3600, name: 'late > 24 hours' , },
  { id: 4, value1: -24*3600, value2:0, name: 'late < 24 hours' , },
  { id: 5, value1: 0, value2:24*3600, name: 'next 24 hours', },
  { id: 6, value1: 24*3600, value2:7*24*3600, name: 'next 7 days', },
  { id: 7, value1: 7*24*3600, value2:30*24*3600, name: 'next 30 days', },
  { id: 8, value1: 30*24*3600, value2:null, name: 'more than 30 days', },
];